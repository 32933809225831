ul.tags {
  display: flex;
  list-style: none;
  padding-left: 0;
  text-align: center;
  margin: 0.9em 0 0;
  padding: 0;
}

.tags .tag {
  width: auto;
  padding: 5px 8px;
  margin: 0 5px;
  border-radius: 13px;
  color: #fff;
  background: #393e47;
  cursor: help;
  font-size: 12px;
}

.tag_10 {
  border: 1px solid #79281f;
  background: #912a1f !important;
}

.tag_7 {
  border: 1px solid #7a7201;
  background: #7a7200 !important;
}

.tag_5 {
  border: 1px solid #025d79;
  background: #037091 !important;
}
