.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.login-wrapper img {
  width: 100px;
  height: 100px;
  margin: 20px;
}

.login-wrapper input {
  color: #cad1d8;
  background-color: #22262c;
  border: 1px solid #333;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem 0.5rem;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
}

#login_error {
  color: red;
  text-align: center;
}
