#wrapper {
  overflow: visible;
  position: absolute;
  width: 100%;
}

#mainMenu {
  position: sticky;
  top: 0;
  margin-top: 0.5em;
  background-color: #121212;
  z-index: 1000;
}

#dropdowns,
#bosses {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 0;
  padding: 0 1rem;
  flex-wrap: wrap;
}

#bosses {
  float: left;
  flex-direction: column;
  max-width: 200px;
}

#instanceType {
  display: grid;
}

#instanceType button:first-child {
  margin-bottom: 0.2rem;
}

#bosses {
  float: left;
  position: sticky;
  top: 3em;
}

/* Main Menu Dark Mode theme buttons */

button {
  color: #cad1d8;
  background-color: #22262c;
  border: 1px solid #333;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem 0.5rem;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
}

button:hover {
  background-color: #31363c;
}

button.active {
  background-color: #313740;
}
