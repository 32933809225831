#journal {
  float: left;
  max-width: 900px;
  width: 50%;
  min-width: 500px;
  margin: auto;
  color: #cad1d8;
  padding: 0em, 0em, 2em;
}

#encounterTitle {
  font-size: 30px;
  text-align: center;
  text-shadow: 2px 2px 2px #000000;
  position: relative;
  line-height: 2.5em;
}

#CollapseButton {
  position: absolute;
  left: 0;
  top: 0em;
}

#encounterTitle .encounter_id {
  color: gray;
  font-size: 20px;
  margin: 0 0.5em;
}

#noJournal {
  font-size: 25px;
  text-align: center;
  text-shadow: 2px 2px 2px #000000;
  position: relative;
  line-height: 2em;
  background-color: #3d434d;
}

.section {
  float: right;
  width: 95%;
  margin: auto;
  text-align: left;
  text-decoration: none;
}

#journal .topSection {
  width: 100%;
}

.title_lang {
  font-size: 20px;
  text-shadow: 2px 2px 2px #000000;
  position: relative;
  height: 2.5em;
  background-color: #3d434d;
  line-height: 2.5em;
  border: 1px solid #2d3139;
  margin-bottom: 1px;
}

.title_lang::before {
  float: left;
  content: '+';
  top: 0;
  left: 0;
  height: 2.5em;
  width: 3em;
  margin: 0 0.3em;
  text-align: center;
}

.title_lang.active {
  background-color: #525a66;
}

.title_lang.active::before {
  content: '-';
}

.title_lang:hover {
  background-color: #525f73;
  cursor: zoom-in;
}
.title_lang.active:hover {
  cursor: zoom-out;
}
.title_lang a {
  all: unset;
}

.bodytext_lang {
  width: 92%;
  margin: 0 1em;
  padding: 1em;
  background-color: #525a66;
  box-shadow: inset 0 0 10px #000000;
  font-size: 16px;
  overflow-wrap: break-word;
}

.hidden {
  display: none;
}

.title_lang .iconFlags {
  position: absolute;
  width: auto;
  right: 1em;
  top: 0;
  line-height: 2.7em;
  cursor: help;
}

.title_lang .iconFlags a {
  display: inline-flex;
  cursor: alias;
}

.title_lang .iconFlags img {
  display: inline-flex;
  align-items: center;
  justify-content: right;
  width: 1em;
  height: 1em;
  margin: 0 0.2em;
}

.wowhead_url {
  text-decoration: none;
  color: #a7e2ff;
  cursor: alias;
}

#comparedBuilds {
  float: left;
  margin-top: 4.8em;
  margin-left: 2em;
  width: 35%;
  color: #cad1d8;
}

#diffLoading {
  float: left;
  margin-top: 4.8em;
  margin-left: 2em;
  width: 35%;
  color: #ffffff;
  line-height: 2.5em;
  font-size: 30px;
  text-align: center;
}

.mythic-title {
  background-color: rebeccapurple !important;
}

.heroic-title {
  background-color: skyblue !important;
}

.fakeLink {
  cursor: copy;
}

.spellIdCopy {
  color: gray;
  font-size: 15px;
  height: 2.5em;
  margin-left: 0.5em;
  cursor: copy;
}

.title_lang .spellIcon {
  float: left;
  display: inline-flex;
  width: 1.5em;
  height: 1.5em;
  line-height: 2.5em;
  margin-top: 0.5em;
  margin-right: 0.5em;
  box-shadow: #a7e2ff 0 0 3px;
  cursor: copy;
}

#diffLoading {
  position: sticky;
  top: 5em;
  z-index: 1000; /* Ensure it stays on top */
}

.diffClickMe {
  cursor: pointer;
}
